import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {}

  expandAll(event) {
    const returnedBenefits = $(event.currentTarget).parents('.returned-benefits');
    $('.tooltip').tooltip('hide');
    returnedBenefits.find('#expand-all').addClass('d-none');
    returnedBenefits.find('#collapse-all').removeClass('d-none');
    returnedBenefits.find('.control__expand').addClass('d-none');
    returnedBenefits.find('.control__collapse').removeClass('d-none');
    returnedBenefits.find('.settings-panel__content').removeClass('d-none');
  }
  
  collapseAll(event) {
    const returnedBenefits = $(event.currentTarget).parents('.returned-benefits');
    $('.tooltip').tooltip('hide');
    returnedBenefits.find('#expand-all').removeClass('d-none');
    returnedBenefits.find('#collapse-all').addClass('d-none');
    returnedBenefits.find('.control__expand').removeClass('d-none');
    returnedBenefits.find('.control__collapse').addClass('d-none');
    returnedBenefits.find('.settings-panel__content').addClass('d-none');
  }

  expand(event) {
    const settingsPanel = $(event.currentTarget).parents('.settings-panel');
    $('.tooltip').tooltip('hide');
    settingsPanel.find('.control__expand').addClass('d-none');
    settingsPanel.find('.control__collapse').removeClass('d-none');
    settingsPanel.find('.settings-panel__content').removeClass('d-none');
  }

  collapse(event) {
    const settingsPanel = $(event.currentTarget).parents('.settings-panel');
    $('.tooltip').tooltip('hide');
    settingsPanel.find('.control__expand').removeClass('d-none');
    settingsPanel.find('.control__collapse').addClass('d-none');
    settingsPanel.find('.settings-panel__content').addClass('d-none');
  }

  highlightToggle(event) {
    const row = $(event.currentTarget).parents('tr');
    row.toggleClass('selected')
  }
}