import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    'form',
    'formCard',
    'left',
    'right',
    'notApplicable',
    'removeBtn',
    'rentalProduct'
  ]

  static values = {
    bothAllowed: Boolean,
    orientation: String,
    timeOfService: String
  }

  connect() {
    this.setupInitialStates();
  }

  formCardTargetConnected() {
    this.updateSelectedCount()
  }

  setupInitialStates() {
    switch (this.orientationValue) {
      case 'bilateral':
        this.leftTarget.click();
        this.rightTarget.click();
        break;
      case 'left':
        this.leftTarget.click();
        break;
      case 'right':
        this.rightTarget.click();
        break;
      case 'not_applicable':
        this.notApplicableTarget.click();
        break;
    }

    if (this.timeOfServiceValue && this.timeOfServiceValue != 'tos_unknown') {
      if(this.element.querySelector(`label[for=patient_agreement_product_time_of_service_${this.timeOfServiceValue}]`)) {
        this.element.querySelector(`label[for=patient_agreement_product_time_of_service_${this.timeOfServiceValue}]`).click()
      }
    }
  }

  markUnchecked(orientation) {
    orientation.checked = false;
    orientation.parentElement.classList.remove('active', 'focus');
  }

  adjustOrientation(event) {
    const { currentTarget } = event;
    if (!currentTarget.checked) {
      currentTarget.blur();
    }

    this.updateCheckedStatus(currentTarget)

    const state = {
      left: this.leftTarget.checked,
      right: this.rightTarget.checked,
      notApplicable: this.notApplicableTarget.checked,
      bothAllowed: this.bothAllowedValue
    };

    if (state.left && state.right && !state.notApplicable) {
      this.handleBothSidesChecked(currentTarget.id, state.bothAllowed);
    }
    else if (!state.left && state.right && state.notApplicable) {
      this.handleRightAndNotApplicable(currentTarget.id);
    }
    else if (state.left && !state.right && state.notApplicable) {
      this.handleLeftAndNotApplicable(currentTarget.id);
    }
    else if (state.left && state.right && state.notApplicable) {
      this.handleAllChecked(currentTarget.id);
    }
    else if (!state.left && state.right && !state.notApplicable) {
      this.handleOnlyRightChecked(state.bothAllowed);
    }
  }

  handleBothSidesChecked(currentTargetId, bothAllowed) {
    if (!bothAllowed) {
      if (currentTargetId === this.leftTarget.id) this.markUnchecked(this.rightTarget);
      if (currentTargetId === this.rightTarget.id) this.markUnchecked(this.leftTarget);
    }
  }

  handleRightAndNotApplicable(currentTargetId) {
    if (currentTargetId === this.rightTarget.id) this.markUnchecked(this.notApplicableTarget);
    if (currentTargetId === this.notApplicableTarget.id) this.markUnchecked(this.rightTarget);
  }

  handleLeftAndNotApplicable(currentTargetId) {
    if (currentTargetId === this.leftTarget.id) this.markUnchecked(this.notApplicableTarget);
    if (currentTargetId === this.notApplicableTarget.id) this.markUnchecked(this.leftTarget);
  }

  handleAllChecked(currentTargetId) {
    if (currentTargetId === this.notApplicableTarget.id) {
      this.markUnchecked(this.leftTarget);
      this.markUnchecked(this.rightTarget);
    } else {
      this.markUnchecked(this.notApplicableTarget);
    }
  }

  handleOnlyRightChecked(bothAllowed) {
    if (!bothAllowed) {
      this.markUnchecked(this.leftTarget);
    }
    this.markUnchecked(this.notApplicableTarget);
  }

  updateCheckedStatus(currentTarget) {
    if (currentTarget.checked == true) {
      $(currentTarget).parents('.card').addClass('card--selected')
    } else {
      $(currentTarget).parents('.card').removeClass('card--selected')
    }
  }

  updateSelectedCount() {
    let $modal = $('#product-selection-modal')
    if (!$modal.hasClass('custom-product')) {
      let selectedCount = $modal.find('.inventory-items-list fieldset.card').length
      $modal.find('.selected-count__count').html(selectedCount)

      if (selectedCount > 1) {
        //Item section has a max-height by default but as soon as you add 2 products it should fit the content
        $modal.find('.item-selection').removeClass('item-selection--scroll')
      } else {
        $modal.find('.item-selection').addClass('item-selection--scroll')
      }

      if (selectedCount == 0) {
        $modal.find('.selected-count').addClass('d-none')
        $modal.find('#no-items-selected-warning').removeClass('d-none')
      } else {
        $modal.find('.selected-count').removeClass('d-none')
        $modal.find('#no-items-selected-warning').addClass('d-none')
      }
    }
  }

  removeForm() {
    let form = this.element.parentElement

    //When adding multiple items, items get grouped inside a form.
    //We need to remove the form only when there is only one item inside it
    if ($(form).find('fieldset.card').length == 1) {
      form.remove()
    } else {
      $(this.element).remove()
    }

    this.enableRentalProduct()
    this.updateSelectedCount()
  }

  enableRentalProduct() {
    if (!this.hasRentalProductTarget || this.rentalProductTarget.value != '1') return

    $(`.rental-product-${this.element.dataset.serialNumber}`).removeClass('d-none')
  }
}
