import { get } from '@rails/request.js'
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["header", "headerContent", "loader", "workItems", "workItemsContent", "coveragesEstimations",
    "coveragesContent", "estimationsContent"]

  static sections = ["headerContent", "estimationsContent"]

  initialize() {
    this.pendingSections = this.constructor.sections
  }

  connect() {
    this.currentPath = window.location.pathname
    this.oauthToken = window.availityOauthToken.innerHTML
    get(this.currentPath + "/coverage_summary", { query: { oauth_token: this.oauthToken }, responseKind: "turbo-stream" })
    get(this.currentPath + "/visit", { query: { oauth_token: this.oauthToken }, responseKind: "turbo-stream" })
    get(this.currentPath + "/estimations", { query: { oauth_token: this.oauthToken }, responseKind: "turbo-stream" })
    // get(this.currentPathValue + "/coverages", { query: { oauth_token: this.oauthToken }, responseKind: "turbo-stream" })
    // get(this.currentPathValue + "/work_items", { query: { oauth_token: this.oauthToken }, responseKind: "turbo-stream" })
    // get(this.currentPathValue + "/expected_benefits", { query: { oauth_token: this.oauthToken }, responseKind: "turbo-stream" })
  }

  showAll() {
    if(this.pendingSections.length == 0) {
      this.headerTarget.classList.remove("d-none")
      this.workItemsTarget.classList.remove("d-none")
      this.coveragesEstimationsTarget.classList.remove("d-none")
      this.loaderTarget.classList.add("d-none")
    }
  }

  headerContentTargetConnected() {
    const index = this.pendingSections.indexOf("headerContent");
    if (index > -1) {
      this.pendingSections.splice(index, 1);
    }
    this.showAll()
  }

  estimationsContentTargetConnected() {
    const index = this.pendingSections.indexOf("estimationsContent");
    if (index > -1) {
      this.pendingSections.splice(index, 1);
    }
    this.showAll()
  }
}


